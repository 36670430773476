import React from 'react';

const Quality = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="180.692"
    height="180.667"
    viewBox="0 0 180.692 180.667"
  >
    <defs>
      <clipPath>
        <rect width="180.692" height="180.667" fill="currentColor" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path)">
      <path
        d="M0,218.511c.276-.831.508-1.679.833-2.491a12.222,12.222,0,0,1,14.484-7.456c14.427,3.773,28.822,7.671,43.23,11.517.836.223,1.679.424,2.517.634.434-1.8.7-3.479,1.248-5.063a17.71,17.71,0,0,1,16.091-12c6.639-.218,13.29-.058,19.934-.144a9.372,9.372,0,0,0,3.206-.7c5.457-2.134,10.725-4.977,16.356-6.391,12.34-3.1,23.88-.52,34.377,6.665,2.473,1.693,4.73,4.084,7.463,4.911,2.86.866,6.174.243,9.289.256,2.823.012,5.646-.016,8.469.01,2.257.021,3.182.96,3.185,3.254q.017,16.937,0,33.876c0,2.464-.931,3.382-3.435,3.4-2.764.024-5.536.139-8.291-.019-8.351-.478-16.521.742-24.662,2.377-2.475.5-4.969.914-7.467,1.28-1.821.266-3.058-.557-3.351-2.056s.723-2.825,2.584-3.157c6.939-1.238,13.849-2.768,20.841-3.537,5.291-.581,10.689-.17,16.04-.2.751,0,1.5,0,2.347,0V213.558c-4.093,0-8.145-.2-12.167.06a13.033,13.033,0,0,1-9.253-2.9c-4.526-3.455-9.233-6.7-14.785-8.411a37.332,37.332,0,0,0-27.157,1.6c-2.25,1.011-4.561,1.915-6.712,3.108a16.777,16.777,0,0,1-8.806,1.891c-6-.118-12-.154-17.994.05a12.629,12.629,0,0,0-11.8,11.533c-.105,1.432.233,1.959,1.785,1.951,11.88-.06,23.76-.033,35.64-.031a10.465,10.465,0,0,1,1.761.042,2.5,2.5,0,0,1,2.332,2.644,2.46,2.46,0,0,1-2.379,2.584,14.1,14.1,0,0,1-1.762.036c-10.057,0-20.116-.12-30.17.053a50.978,50.978,0,0,1-14.576-1.948c-14.886-4.1-29.817-8.037-44.736-12.019a7.108,7.108,0,0,0-8.058,2.852c-2.543,3.91-.787,8.845,3.837,10.6q15.558,5.906,31.144,11.742c8.9,3.337,17.739,6.892,26.757,9.89a86.2,86.2,0,0,0,43.528,2.62c4.216-.782,8.444-1.5,12.666-2.245.173-.031.35-.045.526-.061,1.882-.175,3.046.574,3.291,2.115a2.592,2.592,0,0,1-2.407,3.1c-8.062,1.269-16.134,2.468-24.2,3.7-.46.07-.912.187-1.368.282H89.638a16.071,16.071,0,0,0-1.717-.3,84.653,84.653,0,0,1-23.7-4.9c-18.738-6.955-37.42-14.061-56.127-21.1a11.312,11.312,0,0,1-6.126-5.04A42.974,42.974,0,0,1,0,222.393v-3.882"
        transform="translate(0 -77.723)"
        fill="currentColor"
      />
      <path
        d="M112.449,122.433l-1.77-4.151Q102.777,99.73,94.9,81.17a2.1,2.1,0,0,0-2.307-1.528c-6.51.069-13.021.032-19.849.032.526.655.847,1.1,1.218,1.508,2.981,3.25,5.979,6.484,8.954,9.739,1.426,1.56,1.5,3.043.277,4.182s-2.708.969-4.147-.583c-3.6-3.88-7.207-7.751-10.745-11.686a8.151,8.151,0,0,1,.283-11.726c4.715-4.847,9.472-9.653,14.2-14.484a14.372,14.372,0,0,1,10.74-4.476q25.754.039,51.509,0A14.087,14.087,0,0,1,155.5,56.444c4.834,4.895,9.679,9.78,14.453,14.731a8.144,8.144,0,0,1,.159,11.736q-22.26,24.223-44.583,48.386a8.181,8.181,0,0,1-12.574,0Q100,117.328,87.124,103.293c-1.446-1.571-1.552-3.015-.354-4.182s2.8-.969,4.273.624q9.934,10.754,19.848,21.527c.419.455.847.9,1.27,1.353l.289-.182m6.756,2.292c.368-.792.6-1.263.809-1.746q5.036-11.823,10.067-23.647a13.95,13.95,0,0,1,.9-1.906,2.443,2.443,0,0,1,3.069-1.059,2.375,2.375,0,0,1,1.693,2.753,7.024,7.024,0,0,1-.587,1.831q-4.231,9.965-8.485,19.92c-.241.568-.447,1.151-.669,1.727l.232.131L165.9,79.674c-6.609,0-12.723.126-18.826-.066-2.26-.071-3.33.628-4.031,2.725-.854,2.552-2.04,5-3.138,7.461-.741,1.663-2.118,2.284-3.534,1.706-1.477-.6-2-2.088-1.309-3.833.409-1.036.878-2.05,1.312-3.076.667-1.575,1.327-3.153,2.029-4.825H100.044l19.16,44.959m18.651-50.409a3.049,3.049,0,0,0-.131-.678c-2.654-5.1-5.286-10.216-8.024-15.273a2.383,2.383,0,0,0-1.8-.872c-5.759-.066-11.519-.008-17.277-.066a2.064,2.064,0,0,0-2.15,1.327c-2.4,4.707-4.872,9.374-7.31,14.059-.232.446-.4.927-.644,1.5Zm27.476.049a3.922,3.922,0,0,0-.369-.693c-4.57-4.656-9.094-9.361-13.765-13.915a6.748,6.748,0,0,0-3.822-1.96c-3.929-.258-7.886-.092-11.969-.092,2.8,5.355,5.551,10.66,8.361,15.935a1.653,1.653,0,0,0,1.231.694c6.694.043,13.387.031,20.333.031M73.089,74.19a3.511,3.511,0,0,0,.65.165c6.515.008,13.029.037,19.543-.04a2.366,2.366,0,0,0,1.659-1.124c2.26-4.174,4.424-8.4,6.612-12.614.5-.97.992-1.948,1.591-3.125-3.312,0-6.355.054-9.395-.016a9.485,9.485,0,0,0-7.374,3.091c-4,4.137-8.054,8.212-12.081,12.318-.39.4-.747.83-1.206,1.342"
        transform="translate(-26.27 -20.772)"
        fill="currentColor"
      />
      <path
        d="M224.69,13.638a4.65,4.65,0,0,1-2.86,4.617,12.033,12.033,0,0,0-6.442,6.427,4.908,4.908,0,0,1-8.932.02,12.023,12.023,0,0,0-6.431-6.438,4.594,4.594,0,0,1-2.9-4.458A4.583,4.583,0,0,1,200,9.338a11.983,11.983,0,0,0,6.441-6.428,4.891,4.891,0,0,1,8.927-.038,12.021,12.021,0,0,0,6.425,6.444,4.545,4.545,0,0,1,2.9,4.322m-21.674.169A17.078,17.078,0,0,1,210.93,21.7a17.091,17.091,0,0,1,7.916-7.931,16.928,16.928,0,0,1-7.94-7.868,17.269,17.269,0,0,1-7.89,7.905"
        transform="translate(-78.518 0)"
        fill="currentColor"
      />
      <path
        d="M62.046,170.183a4.54,4.54,0,0,1,2.712-4.517,12.406,12.406,0,0,0,6.674-6.677,4.873,4.873,0,0,1,8.76-.014,12.427,12.427,0,0,0,6.674,6.679,4.867,4.867,0,0,1,.022,8.754,12.434,12.434,0,0,0-6.683,6.669,4.5,4.5,0,0,1-4.368,2.759,4.586,4.586,0,0,1-4.477-2.881,11.948,11.948,0,0,0-6.42-6.449,4.559,4.559,0,0,1-2.895-4.323m13.622-7.806a17.058,17.058,0,0,1-7.72,7.686,17.529,17.529,0,0,1,7.9,7.849,16.709,16.709,0,0,1,7.636-7.756,44.674,44.674,0,0,1-4.34-3.4,45.668,45.668,0,0,1-3.48-4.382"
        transform="translate(-24.714 -62.236)"
        fill="currentColor"
      />
      <path
        d="M52.648,18.008a4.505,4.505,0,0,1,4.4,2.73,12.427,12.427,0,0,0,6.68,6.67A4.46,4.46,0,0,1,66.441,31.8,4.477,4.477,0,0,1,63.7,36.168a12.479,12.479,0,0,0-6.662,6.689,4.863,4.863,0,0,1-8.752-.031A12.428,12.428,0,0,0,41.6,36.155a4.5,4.5,0,0,1-2.738-4.38A4.488,4.488,0,0,1,41.616,27.4a12.439,12.439,0,0,0,6.68-6.67,4.476,4.476,0,0,1,4.352-2.721m.036,21.707a17.268,17.268,0,0,1,7.846-7.967,17.167,17.167,0,0,1-7.87-7.859,17.065,17.065,0,0,1-7.876,7.893,17.264,17.264,0,0,1,7.9,7.933"
        transform="translate(-15.481 -7.173)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Quality;
